.reference-widget .reference-widget-title {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    border-bottom: 1px solid #f1f1f1;
    padding: 16px;
  }
  .reference-widget .reference-widget-body {
    border-bottom: 1px solid #f1f1f1;
    padding: 16px;
  }
  .reference-widget .reference-widget-body .label {
      font-size: 14px;
      color: rgba(0, 0, 0, .4);
    }
  .reference-widget .reference-widget-body .value {
      margin-bottom: 16px;
    }
  .reference-widget .reference-widget-body .value:last-child {
      margin-bottom: 0;
    }
