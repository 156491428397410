.logo{
  margin: 0 7px;
}
  .logo svg{
    margin-top: 1px;
    max-width: 200px;
    max-height: 30px;
    min-height: 12px;
    min-width: 80px;
    vertical-align:middle;
  }
