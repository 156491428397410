.input-helper {
  position: relative;
}

.helper {
  position: absolute;
  top: 100%;
  left: 0;
  text-align: right;
  width: 100%;
  font-size: 14px;
}
