.tree-view-item-component {
  position: relative;
  padding-left: 10px
}
.tree-view-item-component:before {
    content: '';
    position: absolute;
    top: -16px;
    left: 0;
    width: 8px;
    height: 28px;
    border-left: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
  }
