.print-issue {
  display: inline-block;
}

  .print-issue button {
    height: 100%;
  }

.print-modal {
  max-width: 800px;
}
.print-modal .print-header {
  font-size: 22px;
}

.print-modal .print-map .leaflet-container {
  height: 300px;
}

.print-modal .bold {
  font-weight: bold;
}

.print-modal .empty-square {
  height: 150px;
  border: 1px solid #eceeef;
}

.print-modal .underline {
  border-bottom: 1px solid black;
  width: 70%;
  margin-left: 100px;
}

.print-modal .issue-print-header {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}

.print-modal .issue-info {
  margin: 20px 0 20px 0;
}

.print-modal .issue-info .print-map {
  margin: 20px 0 20px 0;
}

.print-modal .signs {
  margin-top: 30px;
}

@media print {
  .modal-header,
  .modal-footer,
  .issue-FOOTER,
  .issue-layout,
  .core-layout,
  .root,
  .photo-label,
  .print-label,
  .checkbox,
  .print-buttons,
  .leaflet-control-zoom,
  .no-print {
    display: none;
  }

  .issue-content {
    page-break-after: always;
  }

  .print-issue-photos {
    -webkit-print-color-adjust: exact;
  }

  .modal {
    position: relative;
  }

  .modal-body, .modal-content {
    border: none;
  }

  .modal-body .col-sm-3, .modal-content .col-sm-3 {
    max-width: 25%;
  }

  .modal-body .col-4, .modal-content .col-4 {
    margin: 0 5px;
  }
}
