.loginpage {
  padding-top: 10%;
}

.logo-text {
  font-size: 26px;
  font-weight: bold;
  margin-left: 3px;
  cursor: default;
}

.green-text {
  color: #39B54A;
}

.logo-image {
  padding-bottom: 20px;
  text-align: center;
}

.title {
  padding-bottom: 20px;
}

.copyright {
  text-align: center;
  font-size: 12px;
}
