.address-combobox-select-row {
    display: flex;
    justify-content: space-between;
  }
  .address-combobox-select {
    flex-grow: 1;
    margin-right: 10px;
  }
  .address-combobox-map {
    margin-top: 5px;
    height: 470px
  }
  .address-combobox-map .map {
      cursor: pointer;
    }
