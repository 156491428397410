.date-range-field-wrapper {
  display: block
}

.date-range-popper {
  max-width: 300px;
}

.date-range__form {
  padding-right: 12px;
}