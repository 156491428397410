li.uikit_68a559e8_components_tabs__TabItem_296c43e4 {
  position: relative;

  -webkit-box-flex: 0 !important;

          flex: 0 0 auto !important;

  list-style-type: none !important;
  margin: 0 !important;
  padding: 0 !important;

  display: block !important;
  box-sizing: border-box !important;
  white-space: nowrap !important
}

li.uikit_68a559e8_components_tabs__TabItem_296c43e4:after {
    content: "";
    display: block;
    width: 100%;

    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;

    background: transparent;
    height: 0px;
    border-bottom: 1px solid #b9d6ff;
  }

li.uikit_68a559e8_components_tabs__TabItem_296c43e4 .uikit_68a559e8_components_tabs__Link_296c43e4 {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: nowrap;
    -webkit-box-align: center;
            align-items: center;

    border: none;
    box-sizing: border-box;
    position: relative;
    text-decoration: none;
    color: #486581;
    height: 40px;
    cursor: pointer;
    background-color: white;

    margin: 0;
    padding: 0 16px 0 16px
  }

li.uikit_68a559e8_components_tabs__TabItem_296c43e4 .uikit_68a559e8_components_tabs__Link_296c43e4:visited {
      color: #486581;
    }

li.uikit_68a559e8_components_tabs__TabItem_296c43e4 .uikit_68a559e8_components_tabs__Link_296c43e4:focus,
    li.uikit_68a559e8_components_tabs__TabItem_296c43e4 .uikit_68a559e8_components_tabs__Link_296c43e4:active {
      outline: none;
      color: inherit;
      background-color: inherit;
    }

li.uikit_68a559e8_components_tabs__TabItem_296c43e4 .uikit_68a559e8_components_tabs__Link_296c43e4 .uikit_68a559e8_components_tabs__BeforeLabel_296c43e4 {
      margin: 0 8px 0 0;
    }

li.uikit_68a559e8_components_tabs__TabItem_296c43e4 .uikit_68a559e8_components_tabs__Link_296c43e4 .uikit_68a559e8_components_tabs__BeforeLabel_296c43e4 .uikit_68a559e8_components_tabs__Icon_296c43e4 {
        width: 24px;
        height: 24px;
      }

li.uikit_68a559e8_components_tabs__TabItem_296c43e4 .uikit_68a559e8_components_tabs__Link_296c43e4 .uikit_68a559e8_components_tabs__AfterLabel_296c43e4 {
      margin: 0 0 0 8px;
    }

li.uikit_68a559e8_components_tabs__TabItem_296c43e4 .uikit_68a559e8_components_tabs__Link_296c43e4 .uikit_68a559e8_components_tabs__AfterLabel_296c43e4 .uikit_68a559e8_components_tabs__Icon_296c43e4 {
        width: 24px;
        height: 24px;
      }

li.uikit_68a559e8_components_tabs__TabItem_296c43e4 .uikit_68a559e8_components_tabs__Link_296c43e4 .uikit_68a559e8_components_tabs__Label_296c43e4 {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.71;

      position: relative;

      display: block;
      display: block;
      overflow: hidden;
      overflow: hidden;

      max-width: 216px;
      margin: 0;
      padding: 0;

      white-space: nowrap;
      letter-spacing: 0.2px;
      text-overflow: clip;

      color: #486581;

      -webkit-box-flex: 0;

              flex: 0 0 auto
    }

li.uikit_68a559e8_components_tabs__TabItem_296c43e4 .uikit_68a559e8_components_tabs__Link_296c43e4 .uikit_68a559e8_components_tabs__Label_296c43e4:after {
        position: absolute;
        top: 0;
        left: 215px;

        display: block;

        width: 0;
        height: 100%;

        content: '';

        box-shadow: 0 0 6px 6px white;
      }

li.uikit_68a559e8_components_tabs__TabItem_296c43e4 .uikit_68a559e8_components_tabs__Link_296c43e4 .uikit_68a559e8_components_tabs__Icon_296c43e4 {
      display: block;

      width: 100%;
      height: 100%;

      fill: #829AB1;
    }

li.uikit_68a559e8_components_tabs__TabItem_296c43e4 .uikit_68a559e8_components_tabs__Link_296c43e4 .uikit_68a559e8_components_tabs__Icon_296c43e4 svg {
        width: 100% !important;
        height: 100% !important;
      }

li.uikit_68a559e8_components_tabs__TabItem_296c43e4:hover:after {
    border-bottom: 2px solid #8bbbff;
  }

li.uikit_68a559e8_components_tabs__TabItem_296c43e4:hover .uikit_68a559e8_components_tabs__Link_296c43e4 {
    background-color: #e7f1ff;
  }

li.uikit_68a559e8_components_tabs__TabItem_296c43e4:hover .uikit_68a559e8_components_tabs__Link_296c43e4 .uikit_68a559e8_components_tabs__Icon_296c43e4 {
      fill: #486581;
    }

li.uikit_68a559e8_components_tabs__TabItem_296c43e4:hover .uikit_68a559e8_components_tabs__Link_296c43e4 .uikit_68a559e8_components_tabs__Label_296c43e4 {
      color: #243B53
    }

li.uikit_68a559e8_components_tabs__TabItem_296c43e4:hover .uikit_68a559e8_components_tabs__Link_296c43e4 .uikit_68a559e8_components_tabs__Label_296c43e4:after {
        box-shadow: 0 0 6px 6px #e7f1ff;
      }

li.uikit_68a559e8_components_tabs__TabItem_296c43e4.uikit_68a559e8_components_tabs__isActive_296c43e4:after {
    border-bottom: 2px solid #006aff;
  }

li.uikit_68a559e8_components_tabs__TabItem_296c43e4.uikit_68a559e8_components_tabs__isActive_296c43e4 .uikit_68a559e8_components_tabs__Label_296c43e4 {
    letter-spacing: normal;
    font-weight: 600;
    color: #102A43;
  }

li.uikit_68a559e8_components_tabs__TabItem_296c43e4.uikit_68a559e8_components_tabs__isActive_296c43e4 .uikit_68a559e8_components_tabs__Icon_296c43e4 {
    fill: #334E68;
  }
