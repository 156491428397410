.gallery .label {
  padding: 2px 2px 10px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
}

.gallery .gallery-flex {
  display: flex;
  flex-wrap: wrap;
}

.gallery .gallery-flex .image-cont {
  position: relative;
  margin: 0 5px 5px 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.gallery .gallery-flex .image-cont:after {
  z-index: 1;
  content: "\f0f6";
  font: normal normal normal 30px/1 FontAwesome;
  position: absolute;
  color: rgba(0, 0, 0, 0.2);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.gallery .gallery-flex .image-cont .btn-cont {
  z-index: 3;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  background: transparent;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.gallery .gallery-flex .image-cont .btn-cont .gallery-btn {
  background: transparent;
  border: 0;
  color: rgba(255, 255, 255, 0.6);
  padding: 1px 6px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.gallery .gallery-flex .image-cont .btn-cont .gallery-btn:hover {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.8);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.gallery .gallery-flex .image-cont .btn-cont .gallery-btn:focus {
  outline: none;
}

.gallery .gallery-flex .image-cont:hover {
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.25), 0 0px 8px rgba(0, 0, 0, 0.22);
  transition: box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.gallery .gallery-flex .image-cont:hover .btn-cont {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  background: rgba(0, 0, 0, 0.2);
  visibility: visible;
  opacity: 1;
}

.gallery .gallery-flex .image-cont .gallery-image {
  z-index: 2;
  position: relative;
  height: 65px;
  width: 85px;
  display: block;
  background-size: cover;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.gallery .gallery-flex .image-cont .gallery-image:hover {
  cursor: pointer;
}
