.dropdown {
  padding-right: 10px;
}
  .dropdown .action.list {
    width: 30px;
    padding-top: 2px;
  }
  .dropdown .dropdown-menu.show {
    border-radius: 2px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.12);
  }
  .dropdown .dropdown-menu.show .rw-selectlist.rw-widget {
      border: none;
      padding: 0;
    }
  .dropdown .dropdown-menu.show .rw-selectlist.rw-widget .rw-list-option label {
          font-size: 14px;
          line-height: 1.29;
        }
  .dropdown .dropdown-menu.show .rw-selectlist.rw-widget .rw-list-option label input:checked {
              background-color: #2880ff;
              border: none;
              box-shadow: none;
            }
  .dropdown .dropdown-menu.show .rw-selectlist.rw-widget .rw-list-option:hover {
          border: none;
          border-radius: 0;
          background-color: rgba(40, 128, 255, 0.1);
        }
  .dropdown .dropdown-menu.show .button-area {
      display: flex;
    }
