.form-actions {
  position: absolute;
  width: 409px;
  left: 0;
  bottom: 0;
  padding: 0 15px;
  border-top: 1px solid rgba(0, 0, 0, .06);
  background-color: #fafafa;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 50px;
}

  .form-actions button {
    background: transparent;
    border: none;
    cursor: pointer
  }

  .form-actions button:focus {
      outline:0;
    }

  .form-actions .clear-form {
    /*TODO: убрать когда синхронизируют версию круда (в админке и модуле согласования)*/
    padding-left: 0 !important;
    opacity: 0.32;
    font-size: 14px;
    font-weight: 600;
    color: #000000;
  }

  .form-actions .apply-form {
    /*TODO: убрать когда синхронизируют версию круда (в админке и модуле согласования)*/
    position: static !important;
    margin-right: 6px;
    font-size: 14px;
    font-weight: 600;
    color: #2880ff;
  }
