.toolbar-text-button{
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
  text-transform: uppercase;
  padding: 9px 15px 9px 15px;
  border: none;
  background: none;
  cursor: pointer
}
.toolbar-text-button:active,
  .toolbar-text-button:focus{
    outline: none;
  }
.toolbar-text-button:hover{
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.05)
  }
.toolbar-text-button:disabled{
    opacity: 0.16;
    background: none;
    cursor: not-allowed;
  }