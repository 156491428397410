.effect-list {
  padding: 8px;
  border-top: solid 1px rgba(0, 0, 0, .1);
}
  .effect-list .effect-list-title {
    color: rgba(0, 0, 0, .8);
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 8px;
  }
  .effect-list .effect-list-item {
    border-radius: 2px;
    background-color: #ffffff;
    border: solid 1px rgba(0, 0, 0, .1);
    margin-bottom: 8px;
  }
  .effect-list .effect-list-item .effect-list-item-title {
      padding: 8px;
    }
.effect-list .effect-list-item:last-child {
    margin-bottom: 0;
  }
