.collapsesContainer {
	padding: 8px;
	background-color: #fafafa;
}

	.collapsesContainer .linksInTypeContainer {
		padding: 8px;
		background-color: #ffffff;
		border-radius: 0 0 2px 2px;
		border-bottom: solid 1px rgba(0, 0, 0, 0.06)
	}

	.collapsesContainer .linksInTypeContainer > *:not(:last-child) {
			margin-bottom: 8px;
		}

	.collapsesContainer .links_container {
		display: flex;
		flex-direction: row;
		padding: 12px 16px 14px;
		border: solid 1px rgba(0, 0, 0, 0.06);
		border-radius: 2px 2px 0 0;
		background-color: #fafafa;
	}

	.collapsesContainer .links_container .links__linkedIssueType {
			font-weight: 600;
		}

	.collapsesContainer .links_container .links__comment {
			margin-top: 8px;
		}

	.collapsesContainer .links_container .links__assignedUser {
			padding: 8px 0 3px;
		}

	.collapsesContainer .links_container .controlButtons {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			margin-left: auto;
			justify-content: center
		}

	.collapsesContainer .links_container .controlButtons > * {
				font-size: 14px;
				font-weight: 600;
				color: #2880ff;
				cursor: pointer;
			}

	.collapsesContainer .links__isEmpty {
		margin-bottom: 66px;
		margin-left: 8px;
	}

.links__infoblock {
	display: flex;
	flex-direction: column;
	flex: 1
}

.links__infoblock.list {
		padding-top: 5px;
		padding-bottom: 10px;
		border-bottom: solid 1px rgba(0, 0, 0, 0.06);
	}

.links__infoblock .links__row {
		display: flex;
		flex-direction: row;
		align-self: stretch
	}

.links__infoblock .links__row > *:nth-child(1) {
			flex: 1;
		}

.links__infoblock .links__row > *:nth-child(2) {
			flex: 2;
		}

.menu-popup.links__popup {
	padding: 0
}

.menu-popup.links__popup > div {
		display: flex;
		flex-direction: column;
	}

.menu-popup.links__popup .menuIcon {
		height: 24px;
		width: 24px;
	}

.menu-popup.links__popup .menuIcon svg {
			width: 24px;
			height: 24px;
		}

.createLinkContainer {
	padding: 16px;
	background-color: #ffffff;
	box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.06);
	border-top: solid 1px rgba(0, 0, 0, 0.06);
}

/*TODO: удалить после редизайна быстрого просмотра*/
.quick-view__wrapper .links__infoblock .links__row {
			flex-direction: column;
		}
.quick-view__wrapper .links__infoblock .links__row:not(:last-child) {
			margin-bottom: 10px;
		}
