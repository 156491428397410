.filter-actions {
  padding: 8px 14px;
  border-bottom: 1px solid rgba(0,0,0,.06);
  background-color: #fafafa;
  height: 40px;
}

  .filter-actions .action-btn-wrapper{
    display: inline-block;
  }

  .filter-actions .action-btn {
    display: inline-block;
    background: none;
    vertical-align: top;
    border: none;
    margin: 3px;
    padding: 0;
    outline: none
  }

  .filter-actions .action-btn:hover {
      cursor: pointer;
    }

  .filter-actions .action-btn:hover svg rect {
          fill: #2475eb;
          transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
        }

  .filter-actions .action-btn:focus {
      outline: none;
    }

  .filter-actions .action-btn i {
      width: 24px;
      height: 24px;
      display: block;
    }

  .filter-actions .action-btn i svg {
        width: 20px;
        height: 20px;
      }

  .filter-actions .action-btn i svg rect {
          transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
        }

  .filter-actions .action-btn.return {
      position: absolute;
      right: 10px;
    }

.filters-popup-manager{
  display: inline-block;
}

.action-popup-close-button {
  display: inline-block;
  position: absolute;
  right: 5px;
  top: 5px;
  border: 0;
  background: transparent;
  color: rgba(0,0,0,.4)
}

.action-popup-close-button:hover {
    cursor: pointer;
    color: #000;
  }
