.map-portal {
  padding-left: 5px;
  display: inline-block;
  position: relative;
}

  .map-portal .call-portal {
    background: none;
    border: none;
    display: inline-block;
    color: #4bba68;
    font-weight: 600;
    padding: 0
  }

  .map-portal .call-portal:hover {
      cursor: pointer;
      text-decoration: underline;
    }

  .map-portal .call-portal:focus,
    .map-portal .call-portal:active {
      outline: none;
    }

.map-cont .backdrop {
    background: #000;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    width: 100%;
    height: 100%;
    opacity: 0.5;
  }

.map-cont .content {
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1041;
  }

.map-cont .content .leaflet-control-layers.leaflet-control {
      margin-top: 70px !important;
      margin-right: 30px;
    }

.map-cont .content .portal-close {
      z-index: inherit;
      position: absolute;
      right: 30px;
      top: 30px;
      width: 30px;
      height: 30px;
      background: white;
      border-radius: 100%;
      color: rgba(0, 0, 0, 0.8);
      border: 0;
      opacity: 0.6;
      text-align: center;
      cursor: pointer
    }

.map-cont .content .portal-close:active,
      .map-cont .content .portal-close:focus {
        outline: none;
      }

.map-cont .content .portal-close:hover {
        opacity: 0.8;
      }

.portal-opened {
  overflow: hidden;
}
