.crud-form-buttons-group{
  display: flex;
}
  .crud-form-buttons-group .crud-submit-button{
    margin-right: 8px;
  }
  .crud-form-buttons-group .crud-form-buttons-save {
    padding-top: 4px;
    padding-right: 16px;
  }

.crud-submit-wrapper {
  position: relative;
  margin-right: 8px;
}

.crud-submit-submitting span {
  color: transparent !important;
}

.crud-submit-spinner {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
