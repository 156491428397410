/* This file is legacy and will be deprecated sinse stable release */

:root {
  /* Text */

  /* Colors */

  /* Secondary */

  /* Negative */

  /* Border */

  /* Form field */

  /* Grid */

  /* Scale */
}

/* html, body {
  font-family: var(--uikit-palette-font-family);
} */

.uikit_d4e01a9d_components_icon__Icon_3778264c {
  display: inline-flex;
}

.uikit_d4e01a9d_components_icon__Icon_3778264c > svg {
    margin-top: auto;
    margin-bottom: auto;
    display: block;
    fill: rgb(0, 0, 1);
  }

.uikit_d4e01a9d_components_icon__Icon_3778264c.uikit_d4e01a9d_components_icon__inheritColor_3778264c > g {
      fill: inherit;
    }

.uikit_d4e01a9d_components_icon__Icon_3778264c.uikit_d4e01a9d_components_icon__inheritColor_3778264c path {
      fill: inherit;
    }

.uikit_d4e01a9d_components_icon__Icon_3778264c.uikit_d4e01a9d_components_icon__inline_3778264c {
    display: inline;
  }

.uikit_d4e01a9d_components_icon__Icon_3778264c.uikit_d4e01a9d_components_icon__extrasmall_3778264c > svg {
      width: 8px;
      height: 8px;
    }

.uikit_d4e01a9d_components_icon__Icon_3778264c.uikit_d4e01a9d_components_icon__small_3778264c > svg {
      width: 16px;
      height: 16px;
    }

.uikit_d4e01a9d_components_icon__Icon_3778264c.uikit_d4e01a9d_components_icon__pony_3778264c > svg {
      width: 18px;
      height: 18px;
    }

.uikit_d4e01a9d_components_icon__Icon_3778264c.uikit_d4e01a9d_components_icon__medium_3778264c > svg {
      width: 24px;
      height: 24px;
    }

.uikit_d4e01a9d_components_icon__Icon_3778264c.uikit_d4e01a9d_components_icon__big_3778264c > svg {
      width: 32px;
      height: 32px;
    }

.uikit_d4e01a9d_components_icon__Icon_3778264c.uikit_d4e01a9d_components_icon__large_3778264c > svg {
      width: 64px;
      height: 64px;
    }
