.status-color-formatter {
  display: flex;
  flex-flow: row nowrap;
}
  .status-color-formatter .color-dot {
    flex: none;
    width: 8px;
    height: 28px;
    border-radius: 7.5px;
    display: block;
    margin-right: 5px;
  }
  .status-color-formatter .color-value {
    flex: none;
    height: 28px;
    font-size: 14px;
    padding: 5px;
    line-height: 16px;
    display: block;
  }