.issue-layout {
  overflow-x: hidden;
  height: 100%;
}

.issue-layout .container-fluid {
  box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.06);
}

.issue-layout .breadcrumbs {
  position: sticky;
  top: 0;
  z-index: 11;
}

.issue-layout .breadcrumb {
  padding: 15px 15px 0 15px;
  margin-bottom: 0;
  background-color: #fff;
}

.issue-layout .breadcrumb .breadcrumb-item {
  font-size: 20px;
  font-weight: 600;
}

.issue-layout .breadcrumb .breadcrumb-item>a {
  color: #000000;
}

.issue-layout .breadcrumb .summary {
  display: inline-block;
  margin-left: 5px;
}
