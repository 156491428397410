.export-to-file .export-to-file-button {
    margin-top: 3px;
    padding: 0;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }

    .export-to-file .export-to-file-button, .export-to-file .export-to-file-button svg {
      width: 24px;
      height: 24px;
    }

  .export-to-file .export-to-file-button:focus {
      outline: none;
    }

.export-to-file-popup {
  padding: 0 !important;
}

.export-to-file-popup .views-select-dropdown-btn {
    text-align: left;
  }
