.loader {
}
.loader-dots {
  display: flex;
}

.loader-dot {
  margin: 2px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #007bff;
  transform: scale(0.5);
  animation: scale-dot 0.7s ease-out infinite
}

.loader-dot:nth-of-type(2) {
    animation-delay: 0.2s;
  }

.loader-dot:nth-of-type(3) {
    animation-delay: 0.3s;
  }

.loader-dot:nth-of-type(4) {
    animation-delay: 0.4s;
  }

.loader-dot:nth-of-type(5) {
    animation-delay: 0.5s;
  }

@keyframes scale-dot {
  0% {
    transform: scale(0.5);
  }
  25% {
    transform: scale(1);
  }
  75% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(0.5);
  }
}
