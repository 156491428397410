.fields-management-list {
  margin-top: 8px;
  min-height: 40px;
  position: relative;
  z-index: 2;
  transition: border 0.4s cubic-bezier(0.4, 0, 0.2, 1)
}
.fields-management-list.dragging {
    position: relative
  }
.fields-management-list.dragging:after {
      content: "";
      position: absolute;
      width: calc(100% + 10px);
      height: calc(100% + 10px);
      top: -5px;
      left: -5px;
      border-radius: 2px;
      transition: border 0.4s cubic-bezier(0.4, 0, 0.2, 1);
      border: dashed 2px rgba(0, 0, 0, 0.1);
    }
.fields-management-list .list-item {
    outline: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    border-radius: 2px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    margin-bottom: 8px;
    transition: box-shadow 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  }
.fields-management-list .list-item .title {
      padding: 8px;
    }
.fields-management-list .fields-management-tab {
    padding-top: 8px;
    padding-bottom: 3px;
    display: flex;
    flex-flow: row nowrap;
  }
.fields-management-list .fields-management-tab .fields-management-tab-drag .fields-management-tab-mover {
        width: 24px;
        height: 24px;
        opacity: 0.32;
        display: inline-block;
      }
.fields-management-list .fields-management-tab .fields-management-tab-drag .fields-management-tab-mover svg {
          width: 24px;
          height: 24px;
        }
.fields-management-list .fields-management-tab .fields-management-tab-title {
      font-size: 16px;
      font-weight: normal;
      flex: 1 0 auto;
    }
.fields-management-list .fields-management-tab .fields-management-tab-buttons {
      flex: 1 0 auto;
      text-align: right;
    }

