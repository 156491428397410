.wm .wm-title {
    font-size: 16px;
    line-height: 30px;
    font-weight: 600;
    color: #000;
    border-bottom: 1px solid #f1f1f1;
    padding: 16px;
  }
  .wm .wm-list {
    padding: 16px;
  }
  .wm .wm-list .wm-item {
      border-radius: 2px;
      background-color: #ffffff;
      border: solid 1px rgba(0, 0, 0, .1);
      margin-bottom: 8px;
    }
  .wm .wm-list .wm-item .wm-name {
        padding: 8px;
      }
  .wm .wm-list .wm-item .btn-icon {
        display: inline-block;
        width: 37px;
        height: 37px;
        padding: 7px;
        transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1)
      }
  .wm .wm-list .wm-item .btn-icon:hover {
          opacity: 1;
          cursor: pointer;
        }
  .wm .wm-list .wm-item .btn-icon:hover svg path {
              fill: rgb(139, 139, 139);
            }
  .wm .wm-list .wm-item .btn-icon svg {
          width: 24px;
          height: 24px;
        }
  .wm .wm-list .wm-item .btn-icon svg path {
            fill: rgb(158, 158, 158);
          }
