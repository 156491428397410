.right-widgets .base-collapse-btn {
    font-size: 20px
  }
  .right-widgets .collapsesContainer .base-collapse-btn {
      font-size: 16px;
    }

.base-collapse-btn {
  border: 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  background: white;
  padding: 16px;
  width: 100%;
  display: block;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  cursor: pointer;
  position: relative;
  transition: ease background-color .2s
}

.base-collapse-btn:focus {
    outline: none;
  }

.base-collapse-btn:hover {
    transition: ease background-color .2s;
    background: #fafafa;
  }

.base-collapse-btn .base-collapse-btn-icon {
    position: absolute;
    top: calc(50% - 12px);
    right: 16px;
    width: 24px;
    height: 24px;
    transition: ease transform .2s
  }

.base-collapse-btn .base-collapse-btn-icon.base-collapse-btn-icon-opened {
      transition: ease transform .2s;
      transform: rotate(180deg);
    }
