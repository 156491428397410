.attributes-tab .btn-icon {
    display: inline-block;
    width: 37px;
    height: 37px;
    padding: 7px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1)
  }

  .attributes-tab .btn-icon:hover {
      opacity: 100%;
      cursor: pointer;
    }

  .attributes-tab .btn-icon:hover svg path {
          fill: rgb(139, 139, 139);
        }

  .attributes-tab .btn-icon svg {
      width: 24px;
      height: 24px;
    }

  .attributes-tab .btn-icon svg path {
        fill: rgb(158, 158, 158);
      }

  .attributes-tab .effectContainer {
    display: inline-block;
    vertical-align: bottom;
  }

  .attributes-tab .effectContainer .effect-icon {
      width: 37px;
      height: 37px;
      padding: 7px;
    }

  .attributes-tab .effectContainer .effect-icon svg {
        width: 24px;
        height: 24px;
      }

  .attributes-tab .effectContainer .effect-icon svg path {
          fill: #2780ff;
        }