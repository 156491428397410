.tab-panel-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
  
  .tab-panel-wrapper .tab-panel {
    background: #fff;
  }
  
  .tab-panel-wrapper .tab-panel .top-tabs {
      display: flex;
      overflow-x: auto;
    }
  
  .tab-panel-wrapper .tab-panel .top-tabs .top-tab {
        padding: 18px;
        color: rgba(0, 0, 0, 0.32);
        line-height:14px;
        font-size: 14px;
        font-weight: 600;
        transition: 0.4s cubic-bezier(0.4, 0, 0.2, 1)
      }
  
  .tab-panel-wrapper .tab-panel .top-tabs .top-tab:hover, .tab-panel-wrapper .tab-panel .top-tabs .top-tab.active {
          text-decoration: none;
          color: rgba(0, 0, 0, 0.8);
          box-shadow: #0082ff 0 -2px inset;
          transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
        }
  
  .tab-panel-wrapper .content {
    height: 100%;
    display: flex;
    flex-grow: 1;
  }

