.system-fields {
  padding: 0 10px 10px 10px;
}
  .system-fields .system-field {
    border-radius: 2px;
    background-color: #ffffff;
    border: solid 1px rgba(0, 0, 0, .1);
    margin-bottom: 8px;
  }
  .system-fields .system-field .system-field-title {
      padding: 8px;
      display: inline-block;
    }
  .system-fields .system-field:hover .btn-icon{
       transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
       opacity: 0.8;
       visibility: visible;
     }
