.edit-conflict-modal {
  min-width: 700px;
}

  .edit-conflict-modal .modal-body {
    height: auto;
    overflow-y: visible;
  }

  .edit-conflict-modal-comments {
    margin-top: 40px;
  }

  .edit-conflict-modal-comments .comments-widget {
      padding-left: 0;
      padding-right: 0;
    }
