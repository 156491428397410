.quick-filters {
  display: flex;
  align-items: center;
  background-color: #fafafa;
  padding: 18px;
  border-bottom: 1px solid rgba(0, 0, 0, .06);
}

  .quick-filters .filters-sign {
    font-size: 16px;
    font-weight: 600;
    margin-right: 18px;
  }

  .quick-filters .filter-label {
    display: flex;
    align-items: center;
  }

  .quick-filters .close {
    display: flex;
    align-content: center;
    opacity: 0.32;
    margin-left: 5px
  }

  .quick-filters .close:hover {
      opacity: 0.7;
    }
