.crud-form .label {
  font-size: 14px;
  text-align: left;
  color: rgba(0, 0, 0, 0.4);
}

.crud-form .issue-FOOTER .fine-uploader-dropzone-container {
  border: 2px dashed #e8e8e8;
}

.crud-form .issue-FOOTER .fine-uploader-dropzone-container .react-fine-uploader-gallery-file-input-container {
  background: #7d989c;
  border: 1px solid #e8e8e8;
}

.crud-form .issue-FOOTER .fine-uploader-dropzone-container .react-fine-uploader-gallery-file-input-container:hover {
  background: #33b6cc;
}

.crud-form .right-widgets-form {
  border-left: 1px solid #f1f1f1;
}

.crud-form {
   border-top: 1px solid #f1f1f1;
}