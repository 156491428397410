.groups-management-list {
  position: relative;
  padding: 10px;
  transition: 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}
  .groups-management-list .groups-management-tab {
    display: flex;
    flex-flow: row nowrap;
  }
  .groups-management-list .groups-management-tab .groups-management-tab-drag .groups-management-tab-mover {
        width: 24px;
        height: 24px;
        opacity: 0.32;
        display: inline-block;
      }
  .groups-management-list .groups-management-tab .groups-management-tab-drag .groups-management-tab-mover svg {
          width: 24px;
          height: 24px;
        }
  .groups-management-list .groups-management-tab .groups-management-tab-title {
      font-size: 16px;
      font-weight: normal;
      flex: 1 0 auto;
    }
  .groups-management-list .groups-management-tab .groups-management-tab-collapser .groups-management-tab-arrow {
        padding: 0 8px 0 0;
        display: flex;
        margin: 0;
        background: none;
        border: none
      }
  .groups-management-list .groups-management-tab .groups-management-tab-collapser .groups-management-tab-arrow:focus {
          outline: none;
        }
  .groups-management-list .groups-management-tab .groups-management-tab-collapser .groups-management-tab-arrow:hover {
           cursor: pointer;
         }
  .groups-management-list .groups-management-tab .groups-management-tab-collapser .groups-management-tab-arrow i {
          width: 24px;
          height: 24px;
        }
  .groups-management-list .groups-management-tab .groups-management-tab-collapser .groups-management-tab-arrow i svg {
            width: 24px;
            height: 24px;
            opacity: 0.32;
            transition: 0.4s cubic-bezier(0.4, 0, 0.2, 1);
          }
  .groups-management-list .groups-management-tab .groups-management-tab-collapser .groups-management-tab-arrow.collapsed svg {
            transform: rotate(180deg);
            transition: 0.4s cubic-bezier(0.4, 0, 0.2, 1);
          }
  .groups-management-list .groups-management-tab .groups-management-tab-buttons {
      flex: 1 0 auto;
      text-align: right;
    }
  .groups-management-list .groups-management-tab .groups-management-tab-buttons .groups-management-tab-arrow {
        padding: 0 8px 0 0;
        margin: 0;
        background: none;
        border: none
      }
  .groups-management-list .groups-management-tab .groups-management-tab-buttons .groups-management-tab-arrow:focus {
          outline: none;
        }
  .groups-management-list .groups-management-tab .groups-management-tab-buttons .groups-management-tab-arrow:hover {
          cursor: pointer;
        }
  .groups-management-list .groups-management-tab .groups-management-tab-buttons .groups-management-tab-arrow svg {
          width: 24px;
          height: 24px;
          opacity: 0.32;
          transition: 0.4s cubic-bezier(0.4, 0, 0.2, 1);
        }
  .groups-management-list .groups-management-tab .groups-management-tab-buttons .groups-management-tab-arrow.collapsed svg {
            transform: rotate(180deg);
            transition: 0.4s cubic-bezier(0.4, 0, 0.2, 1);
          }
  .groups-management-list .groups-management-list-group {
    border-radius: 2px;
    border: solid 1px rgba(0, 0, 0, 0.16);
    outline: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    border-radius: 2px;
    background-color: #ffffff;
    border: solid 1px rgba(0, 0, 0, 0.1);
    margin-bottom: 8px;
    padding: 8px;
    transition: box-shadow 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  }
  .groups-management-list .groups-management-list-group .btn-del-group {
      width: 100%;
      margin-top: 10px;
    }
  .groups-management-list .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    background: rgba(255, 255, 255, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
