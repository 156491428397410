.addresses-management-list {
  margin-top: 10px;
  position: relative;
  transition: 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}
  .addresses-management-list .addresses-management-list-item {
    border-radius: 2px;
    border: solid 1px rgba(0, 0, 0, 0.16);
    outline: none;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
    border-radius: 2px;
    background-color: #ffffff;
    border: solid 1px rgba(0, 0, 0, 0.1);
    margin-bottom: 8px;
    transition: box-shadow 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  }
  .addresses-management-list .addresses-management-list-item .btn-icon {
      display: inline-block;
      width: 37px;
      height: 37px;
      padding: 7px;
      opacity: 0;
      visibility: hidden;
      transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1)
    }
  .addresses-management-list .addresses-management-list-item .btn-icon:hover {
        opacity: 100%;
        cursor: pointer;
      }
  .addresses-management-list .addresses-management-list-item .btn-icon:hover svg .icon-path {
            fill: #C3C3C3;
          }
  .addresses-management-list .addresses-management-list-item .btn-icon svg {
        width: 24px;
        height: 24px;
      }
  .addresses-management-list .addresses-management-list-item .btn-icon svg .icon-path {
          fill: #ACACAC;
        }
  .addresses-management-list .addresses-management-list-item:hover .btn-icon{
       transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
       opacity: 0.8;
       visibility: visible;
     }
  .addresses-management-list .addresses-management-list-item .title {
      padding: 8px;
    }
