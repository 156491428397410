.fields-tab {
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  padding: 0 10px
}
.fields-tab:hover {
    cursor: pointer;
  }
.fields-tab .fields-tab-title {
    font-weight: 600;
    line-height: 38px;
  }
.fields-tab.collapsed {
    margin-bottom: 8px;
  }
.fields-tab .btn-arrow {
    width: 24px;
    height: 24px;
    display: inline-block;
    padding-top: 8px;
  }
.fields-tab .btn-arrow svg {
      width: 24px;
      height: 24px;
      opacity: 0.32;
      transition: 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    }
.fields-tab .btn-arrow:focus {
       outline: none;
     }
.fields-tab .btn-arrow:hover {
       cursor: pointer;
     }
.fields-tab .btn-arrow.collapsed {
    }
.fields-tab.collapsed .btn-arrow svg {
      transform: rotate(180deg);
      transition: 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    }

