.accesses-rights-form {
    width: 100%;
    height: calc(100vh - 106px);
    padding: 25px 30px;
    overflow-y: auto;
}

.accesses-rights-form__select {
    width: 400px;
}

.rights-settings__title > div {
    margin-left: 5px;
    vertical-align: middle;
}

.rights-settings__blocks-row {
    display: flex;
    border-top: 1px solid #f1f1f1;
}

.rights-settings__item {
    padding: 15px 0 30px;
    flex-basis: 33%;
}

.rights-settings__name {
    font-size: 1em;
    margin-bottom: 0;
    font-weight: bold;
}

.rights-settings__error {
    margin: 0;
    color: #dc3545;
}

.rights-settings__error-text {
    vertical-align: middle;
    margin-left: 5px;
}

.rights-settings__field-container {
    margin-bottom: 0.5em;
}

.rights-settings__field-container:first-of-type {
    margin-top: 2em;
}

.rights-settings__checkbox-container > .form-group {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.rights-settings__checkbox-container > .form-group > label {
    margin-right: 10px;
}

.rights-settings__checkbox-container > .form-group label {
    margin-bottom: 0;
}

.rights-settings__linked-fields-label {
    line-height: 1;
    margin-bottom: 0.5em;
}

.rights-settings__linked-fields-value {
    line-height: 1;
}

.rights-settings__footer {
    padding: 30px 0;
    border-top: 1px solid #f1f1f1;
}

.rights-settings__reset-btn {
    margin-left: 20px;
}
