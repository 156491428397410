.pagination .range.btn-secondary.disabled {
  background: transparent;
  border: 0px;
  border-radius: 0;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  line-height: inherit;
  min-width: 65px;
}

.pagination .next-prev button {
  background: transparent;
  border: 0px;
  border-radius: 0;
  line-height: inherit;
  font-size: 14px;
  padding: 12px 15px;
  color: rgba(0, 0, 0, 0.4);
}

.pagination .pagination-limit {
  background: transparent;
  border: 0px;
  border-radius: 0;
  line-height: inherit;
  font-size: 14px;
  padding: 8px 15px;
  color: rgba(0, 0, 0, 0.4);
  display: flex;
  max-width: 300px;
}

.pagination .pagination-limit__header {
    margin: 5px 5px 0 0
  }

.pagination .pagination-limit__dropdown {
    min-width: 80px;
  }

.pagination .next-prev button:focus, .pagination .next-prev button:active {
  outline: none;
  box-shadow: none;
}

.pagination .next-prev button:disabled {
  color: rgba(0, 0, 0, 0.2);
}

.pagination .next-prev button:hover {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.8);
}

.pagination .next-prev button:disabled:hover {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.2);
}
