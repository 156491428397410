.container-fluid.issues-grid {
  padding: 0;
}

.issues-grid {
  height: 100%;
  padding: 0;
  width: 100%;
  overflow: hidden;

}

.issues-grid .grid-row {
    height: 100%;
    width: 100%;
    display: flex;
  }

.issues-grid .grid-row .filter-col {
    border-right: 1px solid rgba(0, 0, 0, 0.06);
  }

.issues-grid .grid-row .filter-col .filter-toggle {
    cursor: pointer;
    width: 25px;
    padding-top: 10px;
    writing-mode: tb-rl;
    font-weight: 600;
    padding-right: 2px;
  }

.issues-grid .grid-row .filter-col .filter-toggle .fa-filter {
    transform: rotate(90deg);
  }

.issues-grid .grid-row .filter-col .filter-header .header-name {
    display: inline-block;
    font-weight: bold;
    font-size: 16px;
    line-height: 14px;
    text-transform: uppercase;
  }

.issues-grid .grid-row .filter-col .filter-header .close-bnt {
    display: inline-block;
    border: 0;
    background: transparent;
    color: rgba(0, 0, 0, 0.4);
  }

.issues-grid .grid-row .filter-col .filter-header .close-bnt:focus {
    outline: none;
  }

.issues-grid .grid-row .filter-col .filter-header .close-bnt:hover {
    cursor: pointer;
    color: black;
  }

.issues-grid .grid-row .filter-col .filter-panel.invisible {
    width: 0;
  }

.issues-grid .grid-row .grid-col {
    position: relative;
    flex: 1 0 auto;
  }

.issues-grid .grid-row .grid-col .react-grid-Container {
    width: 100% !important;
  }

.issues-grid .grid-row .grid-col .react-grid-Container .react-grid-Grid {
    overflow: auto !important;
    height: 100%!important;
  }

.issues-grid .grid-row .grid-col .react-grid-Container .react-grid-Grid .react-grid-HeaderCell {
    padding-top: 11px;
  }

.issues-grid .grid-row .grid-col .react-grid-Container .react-grid-Grid .react-grid-HeaderCell--locked {
    border-right: none;
  }

.issues-grid .grid-row .grid-col .react-grid-Container .react-grid-Grid .react-grid-HeaderRow {
    width: 100% !important;
  }

.issues-grid .grid-row .grid-col .react-grid-Container .react-grid-Grid .react-grid-Canvas {
    width: 100% !important;
  }

.issues-grid > .row:first-child {
    height: 100%;
  }

.issues-grid .grid-group {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 50px;
    padding-top: 50px;
  }

.issues-grid .grid-group--extended {
    padding-top: 100px !important;
  }

.issues-grid .grid-group .react-grid-Main {
    outline: none;
  }

.issues-grid .grid-group .react-grid-Grid {
    border: 0;
  }

.issues-grid .grid-group .react-grid-Grid .react-grid-checkbox-label {
    outline-style: none;
  }

.issues-grid .grid-group .toolbar {
    width: 100%;
    position: absolute;
    height: 50px;
    top: 0;
    left: 0;
    display: flex;
    background-color: #fafafa;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }

.issues-grid .grid-group .quick-filters {
    width: 100%;
    position: absolute;
    height: 50px;
    top: 50px;
    left: 0;
  }

.issues-grid .grid-group .footer {
    z-index: 100;
    width: 100%;
    position: absolute;
    height: 50px;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    background-color: #fafafa;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
  }

.issues-grid .react-grid-Grid {
    border: none;
  }

.issues-grid .react-grid-HeaderCell {
    font-weight: 500;
    font-size: 14px;
    border-left: none;
    border-right: none;
    background-color: transparent;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.4);
    border-bottom: none;
  }

.issues-grid .react-grid-HeaderCell-sortable,
  .issues-grid .widget-HeaderCell__value {
    overflow: hidden;
    text-overflow: ellipsis;
  }

.issues-grid .react-grid-HeaderCell-sortable .pull-right {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.4);
  }

.issues-grid .react-grid-Cell {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }

.issues-grid .react-grid-Cell--locked:last-of-type {
    border-right: none;
  }

.issues-grid .react-grid-Main {
    height: 100%;
    outline: none;
  }

.issues-grid .react-grid-Container {
    height: 100%;
  }

.issues-grid .react-grid-Grid {
    min-height: 100%;
  }

.issues-grid .react-grid-Canvas {
    min-height: 100%;
  }

.issues-grid .react-grid-checkbox-label {
    margin-bottom: 2px;
    position: relative;
  }

.issues-grid .radio-custom + .radio-custom-label:before, .issues-grid .react-grid-checkbox + .react-grid-checkbox-label:before {
    width: 16px;
    height: 16px;
    border-radius: 2px;
    background-color: #fff;
    border: solid 1px rgba(0, 0, 0, 0.16);
  }

.issues-grid .radio-custom + .radio-custom-label:hover:before, .issues-grid .react-grid-checkbox + .react-grid-checkbox-label:hover:before {
    border-color: rgba(0, 0, 0, .32)!important;
  }

.issues-grid .react-grid-checkbox-label:active:before {
    border-color: #0082ff!important;
    background-color: #216bd6;
  }

.issues-grid .react-grid-checkbox:checked + .react-grid-checkbox-label:before {
    background-color: #2880ff;
    border: none;
  }

.issues-grid .react-grid-checkbox:checked + .react-grid-checkbox-label:after {
    content: '';
    position: absolute;
    left: 0px;
    top: 4px;
    width: 16px;
    height: 16px;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4IiB2aWV3Qm94PSIwIDAgMTYgMTYiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDQ1LjIgKDQzNTE0KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5kb25lX3gxNjwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxkZWZzPjwvZGVmcz4KICAgIDxnIGlkPSJTeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iZG9uZV94MTYiPgogICAgICAgICAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlLTM3IiB4PSIwIiB5PSIwIiB3aWR0aD0iMTYiIGhlaWdodD0iMTYiPjwvcmVjdD4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMC4wMDAwMDAsIDIuMDAwMDAwKSIgZmlsbD0iI0ZGRkZGRiIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0xLjcwNzEwNjc4LDYuMjkyODkzMjIgQzEuMzE2NTgyNDksNS45MDIzNjg5MyAwLjY4MzQxNzUxMSw1LjkwMjM2ODkzIDAuMjkyODkzMjE5LDYuMjkyODkzMjIgQy0wLjA5NzYzMTA3MjksNi42ODM0MTc1MSAtMC4wOTc2MzEwNzI5LDcuMzE2NTgyNDkgMC4yOTI4OTMyMTksNy43MDcxMDY3OCBMNC4yOTI4OTMyMiwxMS43MDcxMDY4IEM0LjY4MzQxNzUxLDEyLjA5NzYzMTEgNS4zMTY1ODI0OSwxMi4wOTc2MzExIDUuNzA3MTA2NzgsMTEuNzA3MTA2OCBMMTUuNzA3MTA2OCwxLjcwNzEwNjc4IEMxNi4wOTc2MzExLDEuMzE2NTgyNDkgMTYuMDk3NjMxMSwwLjY4MzQxNzUxMSAxNS43MDcxMDY4LDAuMjkyODkzMjE5IEMxNS4zMTY1ODI1LC0wLjA5NzYzMTA3MjkgMTQuNjgzNDE3NSwtMC4wOTc2MzEwNzI5IDE0LjI5Mjg5MzIsMC4yOTI4OTMyMTkgTDUsOS41ODU3ODY0NCBMMS43MDcxMDY3OCw2LjI5Mjg5MzIyIFoiPjwvcGF0aD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+Cg==');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 12px;
  }

.issues-grid .react-grid-checkbox:checked+.react-grid-checkbox-label:before {
    box-shadow: none;
  }

.issues-grid .react-grid-Row.row-selected .react-grid-Cell, .issues-grid .react-grid-Row.row-selected, .issues-grid .react-grid-Row .row-selected {
    background-color: #f0f0f0;
  }

.issues-grid .react-grid-Row-wrap.row-in-quickview .react-grid-Cell {
    background-color: #e0efff;
  }

.issues-grid .react-grid-HeaderCell__draggable:after {
    content: '';
    width: 1px;
    height: 20px;
    background-color: rgba(0, 0, 0, 0.1);
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -10px;
  }

.issues-grid .react-grid-HeaderRow {
    box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06);
  }

.issues-grid .react-grid-HeaderRow,  .issues-grid .grid-row .grid-col .react-grid-Container .react-grid-Grid .react-grid-HeaderCell--locked {
    background-color: #fafafa;
    box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.06);
  }

.issues-grid .react-grid-HeaderCell--locked:last-of-type {
    padding-bottom: 17px;
  }
