.multi-data-view-tree-view .tree-view-title {
    position: relative;
    z-index: 1;
    font-weight: bold;
    background-color: #ffffff;
  }
  .multi-data-view-tree-view ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  .multi-data-view-tree-view li {
    position: relative;
    padding-left: 8px
  }
  .multi-data-view-tree-view li:before {
       content: '';
       position: absolute;
       top: -16px;
       left: 0;
       width: 6px;
       height: 28px;
       border-left: 1px solid #e6e6e6;
       border-bottom: 1px solid #e6e6e6;
     }
