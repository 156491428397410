.map {
  height: 100%;
}

.map .leaflet-container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.map .leaflet-div-icon {
  border: none;
  background: none;
}
