.issue-view .label {
  font-size: 14px;
  text-align: left;
  color: rgba(0, 0, 0, 0.4);
}

.issue-view .issue-view-title {
  position: relative;
  top: 10px;
  display: inline-block;
  margin-left: 10px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: #ffffff;
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
}

.issue-view .issue-view-field {
  padding: 15px;
}

.issue-view .issue-view-group .col-4:nth-child(n+4) .issue-view-field {
  padding-top: 5px;
}

.issue-view .issue-view-group {
  border-top: 1px solid #f1f1f1;
}

.issue-view .issue-view-group:last-child {
  border-bottom: 1px solid #f1f1f1;
}

.issue-view .issue-HEADING, .issue-view .issue-BODY, .issue-view .issue-FOOTER {
  padding: 15px 15px 20px 15px;
}

.issue-view .issue-HEADING .status-formatter {
  font-size: 14.4px;
}

.issue-view .issue-FOOTER .fine-uploader-dropzone-container {
  border: 2px dashed #e8e8e8;
}

.issue-view .issue-FOOTER .fine-uploader-dropzone-container .react-fine-uploader-gallery-file-input-container {
  background: #7d989c;
  border: 1px solid #e8e8e8;
}

.issue-view .issue-FOOTER .fine-uploader-dropzone-container .react-fine-uploader-gallery-file-input-container:hover {
  background: #33b6cc;
}

.crud-read-heading-item,
.crud-read-body-item {
  margin-bottom: 1rem;
}

.tab-content .issues-grid .grid-group .footer {
  background-color: transparent;
}

.crud-read-container .right-widgets {
  border-left: 1px solid #f1f1f1;
  background-color: #ffffff;
}

.bottom-widgets-wrapper {
  border-top: 1px solid #f1f1f1;
  margin-top: -1px;
  z-index: 11;
  position: relative;
}
