.print-issue-photos {
  padding-top: 15px;
}

.print-issue-photos .issue-image {
  z-index: 2;
  position: relative;
  display: block;
  width: 100%;
}

.print-issue-photos .issue-image:not(last-child) {
  margin-bottom: 15px;
}

.print-issue-photos .toggler {
  width: 100%;
}

.print-issue-photos .checkbox {
  padding-top: 3px;
}
