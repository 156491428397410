.spinner-container {
  display: inline-block;
}

.spinner-center {
  display: block;
  text-align: center;
  margin-top: 2%;
  margin-bottom: 2%;
}

.spinner-full {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: white;
}

.spinner-full > span {
  left: 50%;
  top: 50%;
  margin-left: -14px;
  margin-top: -14px;
}
