.widgets-tabs {
  display: flex;
  border-bottom: 1px solid rgb(240, 240, 240);
  background-color: #fff;
}
  
  .widgets-tabs .top-tab {
    padding: 18px;
    color: rgba(0, 0, 0, 0.32);
    line-height:14px;
    font-size: 14px;
    font-weight: 600;
    transition: 0.4s cubic-bezier(0.4, 0, 0.2, 1)
  }
  
  .widgets-tabs .top-tab:hover, .widgets-tabs .top-tab.active {
      text-decoration: none;
      color: rgba(0, 0, 0, 0.8);
      box-shadow: #0082ff 0 -2px inset;
      transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    }
  
  .widgets-tabs-wrapper {
    width: 100%;
  }
