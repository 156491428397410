.composite-widget .data-button {
    position: relative;
    width: 100%;
  }
    .composite-widget .data-button .portal-opener {
      width: 100%;
      border-radius: 2px;
      font-size: 14px;
      text-align: left;
      background-color: rgba(0, 0, 0, 0.04);
      border: solid 1px rgba(0, 0, 0, 0.08);
      color: rgba(0, 0, 0, 0.4);
      padding: 5px 20px 5px 5px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden
    }
    .composite-widget .data-button .portal-opener:hover {
        background-color: rgba(0, 0, 0, 0.06);
        border: solid 1px rgba(0, 0, 0, 0.1);
        color: rgba(0, 0, 0, 0.6);
      }
    .composite-widget .data-button .data-cleaner {
      background: transparent;
      border: 0px;
      color: rgba(0, 0, 0, 0.4);
      position: absolute;
      top: 0px;
      right: 0px;
      padding: 6px 10px;
      font-size: 14px
    }
    .composite-widget .data-button .data-cleaner:hover {
        cursor: pointer;
        color: rgba(0, 0, 0, 0.8);
      }
    .composite-widget .data-button .data-cleaner:active, .composite-widget .data-button .data-cleaner:focus {
        outline: none;
      }
.popup {
  background: #fff;
  padding: 10px;
  position: absolute;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  z-index: 100;
}
