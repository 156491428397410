.status-formatter .status {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    display: inline-block;
    margin-right: 8px;
    margin-bottom: -2px;
  }
  .status-formatter .with-border {
    border: 1px solid gray;
  }
