
.crud-modal-form div.modal-body--scrollable {
    overflow-y: auto;
    max-height: calc(100vh - 189px);
  }

  .crud-modal-form .modal-body {
    height: auto;
    overflow-y: visible;
  }
