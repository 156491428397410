.btn-add-group {
  margin: 10px;
}

.transparent-btn {
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
}
