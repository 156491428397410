.comments-widget {
  padding: 16px;
}

.comment-box {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 20px;
}

.comment-box .comment-avatar {
  padding-right: 15px;
  max-width: 75px;
}

.comment-box .comment {
  width: 100%;
}

.comment-box .date {
  float: right;
  color: rgba(0, 0, 0, 0.4);
}

.comment-box hr {
  margin: 5px 0;
}

.comment-form {
  padding-bottom: 20px;
}

.comment-form .gallery {
  margin-bottom: 20px;
}

.comment-box .date .date-number {
  padding-left: 5px;
}

.file-list-item {
  padding-left: 16px;
  display: flex;
  border-top: 1px solid #D9E2EC;
}

.file-list-item:hover {
  background-color: #e7f1ff;
}

.file-list-item__active-status {
  margin-right: 8px;
  display: flex;
  align-items: center;
}

.file-list-item__active-status label {
  margin: 0;
}

/* стили для контейнера файла */
.file-list-item > div[class*="file-manager"] {
  background-color: transparent;
}

.file-list-item > div[class*="file-manager"]:hover {
  background-color: transparent;
}

/* стили для иконки файла */
.file-list-item > div[class*="file-manager"] > div:first-of-type {
  display: flex;
  padding: 0;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
}

/* стили для информации о файле */
.file-list-item > div[class*="file-manager"] > div:nth-of-type(2) {
  box-shadow: none;
}
