.read-toolbar {
  position: sticky;
  top: 45px;
  background-color: #fff;
  padding: 10px 15px;
  z-index: 10;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);
  display: flex;
}

  .read-toolbar--quick {
    top: 0;
    white-space: nowrap;
  }

  .read-toolbar--empty {
    padding: 1px 15px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.06);
  }

  .read-toolbar .modal-button-wrapper button {
      height: 100%;
    }

  .read-toolbar button {
    display: flex;
    flex-direction: row;
  }

  .read-toolbar button .uikit-icon {
      margin: auto 4px auto 0px;
      width: 16px;
      height: 16px;
    }

  .read-toolbar button .uikit-icon--centered {
        margin: auto 0px;
      }

  .read-toolbar button .uikit-icon svg {
        width: inherit;
        height: inherit;
      }

  .read-toolbar .transitions-popup button {
      background-color: transparent;
      width: 100%;
    }

  .read-toolbar .transitions-popup button:hover {
      background-color: rgba(0, 0, 0, .03);
      width: 100%;
      box-shadow: 15px 0px 0px 0px rgba(0, 0, 0, .03), -15px 0px 0px 0px rgba(0, 0, 0, .03);
    }

  .read-toolbar .transitions-popup button:not(:last-child) {
      margin-bottom: 8px;
    }

.read-toolbar .toolbar-text-button {
  background-color: rgba(0, 0, 0, .03);
}

.read-toolbar .toolbar-text-button:hover {
  background-color: rgba(0, 0, 0, .05);
}

.read-toolbar .btn-secondary {
  border: solid 1px rgba(0, 0, 0, 0.1);
}

.read-toolbar .status-formatter .status {
  display: none;
}

.read-toolbar .btn {
  padding: .25rem .5rem;
  font-size: .875rem;
  border-radius: .2rem;
  color: #292b2c;
  background-color: #fff;
  border-color: #ccc;
  font-weight: 400;
  line-height: 1.25;
}

.read-toolbar .btn:hover {
  color: #292b2c;
  background-color: #e6e6e6;
}
