.history {
  padding-top: 10px;
}

  .history--empty {
    width: 100%;
    padding: 40px 0;
    font-weight: bold;
    text-align: center;
  }

  .history .history-element {
    padding: 10px 15px;
  }

  .history .date-formatter {
    color: rgba(0, 0, 0, 0.4);
    padding-bottom: 5px;
  }

  .history .new-value {
    border-radius: 2px;
    background-color: rgba(58, 181, 73, 0.2);
    color: rgba(0, 0, 0, 0.8);
    padding: 2px 5px;
  }

  .history .old-value {
    border-radius: 2px;
    background-color: rgba(255, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.8);
    padding: 2px 5px;
    text-decoration: line-through;
  }
