.action-popup-wrapper{
  background: #fff;
  padding: 10px;
  position: absolute;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
  z-index: 300;
}

.action-popup-close-button {
  display: inline-block;
  position: absolute;
  right: 5px;
  top: 5px;
  border: 0;
  background: transparent;
  color: rgba(0,0,0,.4)
}

.action-popup-close-button:hover {
    cursor: pointer;
    color: #000;
  }

.popup__reference{
  display: inline-block
}