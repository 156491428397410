.rw-combobox input.rw-input,
.rw-datetimepicker input.rw-input,
.rw-multiselect,
.rw-numberpicker input.rw-input {
  box-shadow: none;
  font-size: 14px;
}

.rw-datetimepicker .rw-btn {
    line-height: 1em;
  }

.picker-wrapper {
  position: relative;
}

.picker-wrapper .btn-clear {
    visibility: hidden;
    position: absolute;
    background: transparent;
    border: 0;
    right: 58px;
    padding: 7px;
    font-size: 14px;
    z-index: 1;
    color: rgba(0, 0, 0, 0.4)
  }

.picker-wrapper .btn-clear:hover {
      cursor: pointer;
      color: rgba(0, 0, 0, 0.6);
    }

.picker-wrapper .btn-clear:active,
    .picker-wrapper .btn-clear:focus {
      outline: none;
    }

.picker-wrapper .btn-clear--without-time {
      right: 30px;
    }

.picker-wrapper:hover .btn-clear {
    visibility: visible;
  }
