.add-btn {
  display: inline-block;
  background: none;
  vertical-align: top;
  border: none;
  margin: 3px;
  padding: 0
}
.add-btn:hover {
    cursor: pointer;
  }
.add-btn:hover svg rect {
        fill: #2475eb;
        transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
      }
.add-btn i {
    width: 24px;
    height: 24px;
    display: block;
  }
.add-btn i svg {
      width: 24px;
      height: 24px;
    }
.add-btn i svg rect {
        transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
      }
