.quick-view__scroll-wrapper{
    overflow: auto;
    height: 100%;
  }
  .quick-view__wrapper{
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 50%;
    background: white;
    border: 1px solid rgba(0, 0, 0, .06);
  }
  .quick-view__close-button{
    position: absolute;
    top: 15px;
    right: 25px;
    z-index: 15;
    font-size: 1.7rem;
  }