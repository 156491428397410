.select-list input.rw-select-list-input{
    display: none
  }
  .select-list .rw-select-list-label{
    padding-left: 0;
  }
  .select-list .rw-widget-container {
    max-height: calc(100vh - 188px);
    border: 0
  }
  @media (max-width: 1200px) {

  .select-list .rw-widget-container {
      max-height: calc(100vh - 188px)
  }
    }
  .select-list .rw-widget-container .rw-state-focus {
      box-shadow: none;
    }
  .select-list .rw-list-option:hover, .select-list .rw-list-option:hover.rw-state-focus {
    background-color: rgba(40, 128, 255, .1);
    border-color: rgba(40, 128, 255, .1);
  }

.dropdown .dropdown-menu {
  padding: 0;
}

.dropdown .dropdown-menu.show .rw-selectlist.rw-widget {
  max-height: 600px;
  overflow: auto;
}

.dropdown .dropdown-menu.show .button-area {
  border-top: 1px solid #f1f1f1;
}
